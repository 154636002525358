<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-toolbar-title> Nuevo evento </v-toolbar-title>
    </v-toolbar>
    <div class="pa-4">
      <v-row>
        <!-- <v-col cols="12">
          <v-select
            filled
            hide-details
            label="Tipo del evento"
            :items="tiposEvento"
            item-text="nombre"
            item-value="idTipoEvento"
            v-model="evento.idTipoEvento"
            :rules="[rules.req]"
          >
            <template v-slot:item="{ item }">
              <v-list-item-group>
                <v-list-item>
                  <v-chip dark label :color="item.color">
                    <v-icon left>{{ item.icon }}</v-icon>
                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                  </v-chip>
                </v-list-item>
              </v-list-item-group>
            </template>
          </v-select>
        </v-col> -->

        <v-col cols="12">
          <v-item-group v-model="evento.idTipoEvento" class="d-flex align-center flex-wrap mt-2" style="gap: 5px" active-class="x" >
            <v-item v-for="tipo in tiposEvento" :key="tipo.idTipoEvento" :value="tipo.idTipoEvento" v-slot="{ toggle, active }">
              <v-card
                outlined
                class="item-selection align-center pa-2 ma-1"
                @click.stop="toggle"
                :color="active ? tipo.color : null"
                :style="{ borderColor: tipo.color }"
                :dark="active"
              >
                <h4><v-icon left>{{ tipo.icon }}</v-icon>{{ tipo.nombre }}</h4>
              </v-card>
            </v-item>
          </v-item-group>
        </v-col>

        <v-col cols="12" sm="6">
          <datetime-picker
            clear-text="Cancelar"
            ok-text="Aceptar"
            :text-field-props="{
              filled: true,
              hideDetails: 'auto',
              rules: [rules.req,],
            }"
            :time-picker-props="{ format: '24hr' }"
            :date-picker-props="{ min: minDate }"
            label="Inicio del evento"
            v-model="evento.start"
          >
            <template v-slot:dateIcon>
              <v-icon left>mdi-calendar</v-icon>
              Fecha
            </template>
            <template v-slot:timeIcon>
              <v-icon left>mdi-clock-outline</v-icon>
              Hora
            </template>
            <template v-slot:actions="{ parent }">
              <v-btn
                color="grey lighten-1"
                text
                @click.native="parent.clearHandler"
                >{{ parent.clearText }}</v-btn
              >
              <v-btn color="primary" text @click="parent.okHandler">{{
                parent.okText
              }}</v-btn>
            </template>
          </datetime-picker>
        </v-col>
        <v-col cols="12" sm="6">
          <datetime-picker
            clear-text="Cancelar"
            ok-text="Aceptar"
            :text-field-props="{
              filled: true,
              hideDetails: 'auto',
              rules: [rules.req,],
            }"
            :time-picker-props="{ format: '24hr' }"
            :date-picker-props="{ min: minDate }"
            label="Final del evento"
            v-model="evento.end"
          >
            <template v-slot:dateIcon>
              <v-icon left>mdi-calendar</v-icon>
              Fecha
            </template>
            <template v-slot:timeIcon>
              <v-icon left>mdi-clock-outline</v-icon>
              Hora
            </template>
            <template v-slot:actions="{ parent }">
              <v-btn
                color="grey lighten-1"
                text
                @click.native="parent.clearHandler"
                >{{ parent.clearText }}</v-btn
              >
              <v-btn color="primary" text @click="parent.okHandler">{{
                parent.okText
              }}</v-btn>
            </template>
          </datetime-picker>
        </v-col>

        <v-col cols="12">
          <v-textarea
            filled
            hide-details
            no-resize
            auto-grow
            label="Comentario"
            v-model="evento.comentario"
          >
          </v-textarea>
        </v-col>
      </v-row>
    </div>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn type="submit" color="primary" @click.stop="addEvento">
        <v-icon left>mdi-calendar-plus</v-icon>
        Agregar</v-btn
      >
    </v-card-actions>

    <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>
import { req } from '@/utils/validations'

export default {
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    DatetimePicker: () => import("./DateTimePicker.vue"),
  },
  props: {
    estudio: Number | String,
    startingDate: String,
  },
  data() {
    return {
      rules: { req },
      evento: {
        idTipo: null,
        start: this.startingDate ? new Date(this.startingDate) : new Date(),
        end: this.startingDate ? new Date(this.startingDate) : new Date(),
        comentario: null,
      },
      tiposEvento: [],
      minDate: new Date().toISOString(),
    };
  },
  methods: {
    async getTiposEvento() {
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/tipo_evento`,
      });

      this.tiposEvento = data;
    },
    nonPastDates(v) {
      return (
        new Date(v).getTime() >= new Date().getTime() ||
        "Esta fecha ya ha pasado"
      );
    },
    convertUTCDateToLocalDate(date) {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:00`
    },
    async addEvento() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/calendario_eventos/add`,
        data: {
          idEstudio: this.estudio,
          ...this.evento,
          start: this.convertUTCDateToLocalDate(this.evento.start),
          end: this.convertUTCDateToLocalDate(this.evento.end),
        },
      });

      this.$emit("close");
      this.$emit("reload");
    },
  },
  computed: {
    minEndDate() {
      return this.evento.start.toISOString();
    },
  },
  mounted() {
    this.getTiposEvento();
  },
};
</script>
