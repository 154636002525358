var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" Nuevo evento ")])],1),_c('div',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-item-group',{staticClass:"d-flex align-center flex-wrap mt-2",staticStyle:{"gap":"5px"},attrs:{"active-class":"x"},model:{value:(_vm.evento.idTipoEvento),callback:function ($$v) {_vm.$set(_vm.evento, "idTipoEvento", $$v)},expression:"evento.idTipoEvento"}},_vm._l((_vm.tiposEvento),function(tipo){return _c('v-item',{key:tipo.idTipoEvento,attrs:{"value":tipo.idTipoEvento},scopedSlots:_vm._u([{key:"default",fn:function({ toggle, active }){return [_c('v-card',{staticClass:"item-selection align-center pa-2 ma-1",style:({ borderColor: tipo.color }),attrs:{"outlined":"","color":active ? tipo.color : null,"dark":active},on:{"click":function($event){$event.stopPropagation();return toggle.apply(null, arguments)}}},[_c('h4',[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(tipo.icon))]),_vm._v(_vm._s(tipo.nombre))],1)])]}}],null,true)})}),1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('datetime-picker',{attrs:{"clear-text":"Cancelar","ok-text":"Aceptar","text-field-props":{
            filled: true,
            hideDetails: 'auto',
            rules: [_vm.rules.req,],
          },"time-picker-props":{ format: '24hr' },"date-picker-props":{ min: _vm.minDate },"label":"Inicio del evento"},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar")]),_vm._v(" Fecha ")]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clock-outline")]),_vm._v(" Hora ")]},proxy:true},{key:"actions",fn:function({ parent }){return [_c('v-btn',{attrs:{"color":"grey lighten-1","text":""},nativeOn:{"click":function($event){return parent.clearHandler.apply(null, arguments)}}},[_vm._v(_vm._s(parent.clearText))]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":parent.okHandler}},[_vm._v(_vm._s(parent.okText))])]}}]),model:{value:(_vm.evento.start),callback:function ($$v) {_vm.$set(_vm.evento, "start", $$v)},expression:"evento.start"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('datetime-picker',{attrs:{"clear-text":"Cancelar","ok-text":"Aceptar","text-field-props":{
            filled: true,
            hideDetails: 'auto',
            rules: [_vm.rules.req,],
          },"time-picker-props":{ format: '24hr' },"date-picker-props":{ min: _vm.minDate },"label":"Final del evento"},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar")]),_vm._v(" Fecha ")]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clock-outline")]),_vm._v(" Hora ")]},proxy:true},{key:"actions",fn:function({ parent }){return [_c('v-btn',{attrs:{"color":"grey lighten-1","text":""},nativeOn:{"click":function($event){return parent.clearHandler.apply(null, arguments)}}},[_vm._v(_vm._s(parent.clearText))]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":parent.okHandler}},[_vm._v(_vm._s(parent.okText))])]}}]),model:{value:(_vm.evento.end),callback:function ($$v) {_vm.$set(_vm.evento, "end", $$v)},expression:"evento.end"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"filled":"","hide-details":"","no-resize":"","auto-grow":"","label":"Comentario"},model:{value:(_vm.evento.comentario),callback:function ($$v) {_vm.$set(_vm.evento, "comentario", $$v)},expression:"evento.comentario"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addEvento.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar-plus")]),_vm._v(" Agregar")],1)],1),_c('ConfirmDialog',{ref:"confirm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }